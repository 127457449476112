import { formatCompanyForSdkEvent } from '@/util/company'
import { pick } from 'lodash-es'
import { PRODUCTS } from '@/util/constants'
import { getLastNCharacters } from '@/util/general'

/**
 * Creates task status update payload from task workflow orchestrator message
 * @param {*} message - The message from the task workflow orchestrator
 */
export function createTaskStatusUpdatedPayload(message) {
  const taskId = message?.data?.event?.data?.taskId

  const selection = message?.data?.state?.userCompanySelections?.find(
    (selection) => selection.tasks.some((task) => task.taskId === taskId),
  )

  const task = selection?.tasks?.find((task) => task.taskId === taskId)

  return {
    taskId,
    product: task?.product,
    company: formatCompanyForSdkEvent(selection?.company),
    status: task?.status,
    ...(task?.product === PRODUCTS.SWITCH && {
      switchData: {
        paymentMethod: _formatPaymentMethod(
          task?.settings?.switch?.paymentMethod,
        ),
      },
    }),
    ...(task?.failReason && {
      failReason: task.failReason,
    }),
    ...(task?.managedBy && {
      managedBy: task.managedBy,
    }),
  }
}

function _formatPaymentMethod(paymentMethod) {
  const withLastFourAccountNumber = {
    ...paymentMethod,
    ...(paymentMethod.accountNumber && {
      lastFourAccountNumber: getLastNCharacters(paymentMethod.accountNumber, 4),
    }),
  }

  return pick(withLastFourAccountNumber, [
    '_id',
    'title',
    'type',
    'expiry',
    'brand',
    'lastFour',
    'routingNumber',
    'accountType',
    'lastFourAccountNumber',
  ])
}
