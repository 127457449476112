import Analytics from '@/plugins/analytics'
import { PRODUCTS } from '@/util/constants'
import { initTaskWorkflow, taskGenerator } from '@/util/task-workflow'
import { createUserActionTaskPage } from '@/util/user-action'
import { SDK_EVENT_TYPES } from '@/util/constants'
import { appClose } from '@/util/app-event'

export function completeUserActionHandledByTask() {
  Analytics.get().track({
    event: 'Completed User Action',
  })
}

export async function completeUserActionHandledByWebView({ store, companyId }) {
  Analytics.get().track({
    event: 'Completed User Action',
  })

  // hydrate the company store since it doesn't get hydrated for webview handled actions
  await store.dispatch('company/fetchCompanyDetails', companyId)

  if (store.getters['taskWorkflow/userActionAccountIsLinked']) {
    await createUserActionTaskPage({ store })
    const userAction = store.getters['taskWorkflow/userAction']

    // init a task workflow with a 'refresh' action task to get updated account data
    const sdkStructuredRefreshTask = {
      operation: PRODUCTS.ACTION,
      // NOTE more information could be added in the future for additional details about the webview action that triggered this refresh to run
      // ie {flow: 'refresh', triggeredBy: 'view-account'|'change-plan'|'remove-add-on' }
      action: {
        id: btoa(
          JSON.stringify({
            ...userAction,
            flow: 'refresh',
            writeAction: {
              ...userAction,
              actionType: userAction.type,
            },
          }),
        ),
      },
    }

    await store.dispatch(
      'taskWorkflow/updateTaskWorkflowTasks',
      taskGenerator({ tasks: [sdkStructuredRefreshTask], store }),
    )
    await initTaskWorkflow({ store })
  }

  appClose({ store, eventType: SDK_EVENT_TYPES.FINISH })
}
