<template>
  <div>
    <p v-if="data.label" id="form-select-label" class="subtitle paragraph">
      {{ data.label }}
    </p>
    <ul
      role="listbox"
      tabindex="0"
      class="select-wrap"
      :class="{ dark: useDarkMode }"
      @keyup.up="handleSelectPreviousOption"
      @keyup.left="handleSelectPreviousOption"
      @keyup.down="handleSelectNextOption"
      @keyup.right="handleSelectNextOption"
      @keyup.enter="handleEnter"
      :aria-labelledby="ariaLabelledBy"
      :aria-activedescendant="selectedOptionId"
    >
      <FormSelectButton
        v-for="(option, index) in options"
        :option="option"
        :index="index"
        :selectedIndex="selectedIndex"
        @selectOption="selectOption"
        role="option"
        ref="option"
        :key="option.value"
        :arrow="arrows"
      >
        <template v-if="hasSlotOptionContent" v-slot:content>
          <slot name="option-content" v-bind="option"></slot>
        </template>
      </FormSelectButton>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormSelectButton from '@/components/FormTypes/FormSelectButton.vue'
import { pickBy } from 'lodash-es'

export default {
  name: 'FormMultiSelect',
  components: {
    FormSelectButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    initValue: String,
    focusDelay: {
      type: Number,
      default: 100,
    },
    /** To be used when selection auto-advances the user to the next page */
    arrows: Boolean,
    ignoreInitUpdateEvent: Boolean,
  },
  emits: ['inputUpdate', 'inputEnter'],
  data: () => ({
    selectedIndex: -1,
    options: [],
  }),
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    selectedOption() {
      return this.options[this.selectedIndex]?.id
    },
    selectedOptionId() {
      return `${this.selectedOption}-option`
    },
    ariaLabelledBy() {
      return this.data.label ? 'form-select-label' : 'form-flow-title'
    },
    hasSlotOptionContent() {
      return !!this.$slots['option-content']
    },
  },
  methods: {
    selectOption({ option, index, emitUpdate = true }) {
      // If option is disabled, prevent user from selecting it
      if (option.disabled) return

      const inputData = {
        ...this.data,
        ...pickBy(option, (field) => !!field),
        selected: !this.options[index].selected,
      }

      this.options[index].selected = !this.options[index].selected
      this.selectedIndex = index

      this.$refs.option[index]?.$el?.focus()

      if (emitUpdate) {
        this.$emit('inputUpdate', inputData)
      }
    },
    handleSelectPreviousOption() {
      const nextIdx =
        this.selectedIndex === 0
          ? this.options.length - 1
          : this.selectedIndex - 1

      this.selectOption({ option: this.options[nextIdx], index: nextIdx })
    },
    handleSelectNextOption() {
      const nextIdx =
        this.selectedIndex === this.options.length - 1
          ? 0
          : this.selectedIndex + 1

      this.selectOption({ option: this.options[nextIdx], index: nextIdx })
    },
    handleEnter() {
      this.$emit('inputEnter')
    },
  },
  created() {
    this.options = [
      ...this.data.options.map((option) => ({
        ...option,
        selected: option.selected || false,
      })),
    ]

    if (this.initValue) {
      const searchFn = (option) => option.value === this.initValue
      const selectedOption = this.options.find(searchFn)
      const selectedIndex = this.options.findIndex(searchFn)
      this.selectOption({
        option: selectedOption,
        index: selectedIndex,
        emitUpdate: !this.ignoreInitUpdateEvent,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.select-wrap {
  position: relative;

  &:focus {
    outline: none;
  }
}

.subtitle {
  font-size: 1.4rem;
  margin-top: 8px;
  display: block;
}
</style>
