<template>
  <div class="skeleton-template">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SkeletonTemplate',
}
</script>

<style lang="scss" scoped>
.skeleton-template {
  height: 100%;
  padding: var(--screenPaddingValues);
  display: flex;
  flex-direction: column;
}
</style>
