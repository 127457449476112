import { USER_MESSAGE_TYPES } from '@/util/constants'

export function createTaskWorkflowOrchestrator() {
  let Ably
  let channelName
  const subscriptions = new Map()

  return {
    initialize({ Ably: _ably, channelName: _channelName }) {
      Ably = _ably
      channelName = _channelName
    },
    /**
     *
     * @param {Object} options
     * @param {string[]} options.messageTypes - Message types to subscribe to. If USER_MESSAGE_TYPES.ALL_EVENTS is included, the subscription will handle all message types.
     * @param {string} options.taskWorkflowId - Task workflow to subscribe to. If not provided, the subscription will handle all task workflows.
     * @param {Function} options.subscriptionHandler - Function to handle the message.
     */
    subscribe({ messageTypes = [], taskWorkflowId, subscriptionHandler }) {
      const subscriptionHandlerToStore = (message) => {
        const handleAllEvents =
          messageTypes.includes(USER_MESSAGE_TYPES.ALL_EVENTS) ||
          !taskWorkflowId
        const matchesSubscribedTaskWorkflowId =
          taskWorkflowId &&
          taskWorkflowId === message.data.event.data.taskWorkflowId

        if (handleAllEvents || matchesSubscribedTaskWorkflowId) {
          subscriptionHandler(message)
        }
      }

      this.unsubscribe({ messageTypes, taskWorkflowId })

      for (const messageType of messageTypes) {
        const subscriptionKey = _getSubscriptionKey({
          messageType,
          taskWorkflowId,
        })

        subscriptions.set(subscriptionKey, subscriptionHandlerToStore)

        const channel = _getChannel()

        if (messageType === USER_MESSAGE_TYPES.ALL_EVENTS) {
          channel.subscribe(subscriptionHandlerToStore)
        } else {
          channel.subscribe(messageType, subscriptionHandlerToStore)
        }
      }
    },
    /**
     *
     * @param {Object} options
     * @param {string[]} options.messageTypes - Message types to unsubscribe from. If not provided, all subscriptions for the task workflow will be unsubscribed.
     * @param {string} options.taskWorkflowId - Task workflow to unsubscribe from.
     */
    unsubscribe({ messageTypes, taskWorkflowId }) {
      if (!messageTypes) {
        _unsubscribeFromAllTaskWorkflowMessages({ taskWorkflowId })
        return
      }

      const channel = _getChannel()

      for (const messageType of messageTypes) {
        const subscriptionKey = _getSubscriptionKey({
          messageType,
          taskWorkflowId,
        })
        const subscriptionHandler = subscriptions.get(subscriptionKey)

        if (!subscriptionHandler) {
          continue
        }

        subscriptions.delete(subscriptionKey)

        if (subscriptionKey === USER_MESSAGE_TYPES.ALL_EVENTS) {
          channel.unsubscribe(subscriptionHandler)
        } else {
          channel.unsubscribe(messageType, subscriptionHandler)
        }
      }
    },
  }

  function _unsubscribeFromAllTaskWorkflowMessages({ taskWorkflowId }) {
    const channel = _getChannel()

    for (const [
      subscriptionKey,
      subscriptionHandler,
    ] of subscriptions.entries()) {
      const { taskWorkflowId: currentTaskWorkflowId, messageType } =
        _decodeSubscriptionKey(subscriptionKey)

      if (currentTaskWorkflowId === taskWorkflowId) {
        channel.unsubscribe(messageType, subscriptionHandler)
        subscriptions.delete(subscriptionKey)
      }
    }
  }

  function _getChannel() {
    return Ably.get().channels.get(channelName)
  }
}

function _getSubscriptionKey({ messageType, taskWorkflowId }) {
  if (!taskWorkflowId) {
    return messageType
  }

  return `${messageType}:${taskWorkflowId}`
}

function _decodeSubscriptionKey(subscriptionKey) {
  const [messageType, taskWorkflowId] = subscriptionKey.split(':')
  return { messageType, taskWorkflowId }
}
