import { FILTERED_PAYROLL_PROVIDERS } from '@/util/constants'

// When users enter the multiple payroll provider view, they are presented with
// a list of payroll providers. Some of these are just never appropriate or
// correct so filter them out.
export function isNotAvailableForMultiplePayrollProviderFlow({ company }) {
  return FILTERED_PAYROLL_PROVIDERS.has(company._id)
}

/** Includes only the fields that are needed for SDK events */
export function formatCompanyForSdkEvent(company) {
  const { _id, name, branding } = company
  const { logo, color } = branding
  const { url, backgroundColor } = logo

  return {
    _id,
    name,
    branding: {
      logo: { url, backgroundColor },
      color,
    },
  }
}
