<template>
  <div class="survey-wrap" v-focus-trap>
    <ScrollableWrap
      square-edges
      data-test-id="content-scroll"
      custom-bottom-padding="75px"
    >
      <div class="survey-content">
        <div
          v-for="(question, index) in survey.questions"
          class="survey-block"
          :key="index"
        >
          <FocusHeading
            v-if="index < 1"
            :level="4"
            class="survey-question"
            :class="{ dark: useDarkMode }"
          >
            {{ question.title }}
          </FocusHeading>
          <FormSelect
            v-if="question.type === $CONSTANTS.USER_INPUT_ELEMENT_TYPES.RADIO"
            :data="{
              options: randomizedSurveyQuestionAnswers(question),
            }"
            :focus-delay="400"
            @inputUpdate="
              (response) => handleInput({ question, response, index })
            "
            aria-labelledby="modalLabel"
            condensed
          ></FormSelect>
          <FormMultiSelect
            v-if="
              question.type === $CONSTANTS.USER_INPUT_ELEMENT_TYPES.MULTISELECT
            "
            :data="{
              options: randomizedSurveyQuestionAnswers(question),
            }"
            :focus-delay="400"
            @inputUpdate="
              (response) => handleInput({ question, response, index })
            "
            aria-labelledby="modalLabel"
            condensed
          ></FormMultiSelect>
          <FormInput
            v-if="question.type === $CONSTANTS.USER_INPUT_ELEMENT_TYPES.INPUT"
            :data="{
              type: question.type,
              placeholder: question.placeholder || 'Your answer',
            }"
            autocomplete="off"
            @inputUpdate="
              (response) => handleInput({ question, response, index })
            "
            condensed
          ></FormInput>
          <FormInput
            v-if="otherValueInput"
            :data="{
              type: $CONSTANTS.USER_INPUT_ELEMENT_TYPES.INPUT,
              placeholder: otherValueInput.placeholder || 'Your answer',
              description: otherValueInput.description,
            }"
            class="other-input"
            autocomplete="off"
            @inputUpdate="(response) => handleOtherInput({ response })"
            condensed
          ></FormInput>
        </div>
      </div>
    </ScrollableWrap>
    <div class="survey-footer">
      <div class="action-list">
        <BaseButton
          :text="survey.submitButton"
          btn-style="solid"
          :isDisabled="!answeredAllQuestions"
          @handleClick="triggerRecordSurvey"
        />
        <TextButton
          v-if="survey.skipButton"
          :text="survey.skipButton"
          @handleClick="triggerSkipSurvey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Form/BaseButton.vue'
import TextButton from '@/components/Form/TextButton.vue'
import ScrollableWrap from '@/components/Layout/ScrollableWrap.vue'
import FormSelect from '@/components/FormTypes/FormSelect.vue'
import FormMultiSelect from '@/components/FormTypes/FormMultiSelect.vue'
import FormInput from '@/components/FormTypes/FormInput.vue'
import { mapGetters } from 'vuex'
import { getSurvey, closeSurvey } from '@/util/surveys'
import { prepareKeyboardFocus } from '@/util/magic-focus'
import FocusHeading from '@/components/Shared/FocusHeading.vue'
import { USER_INPUT_ELEMENT_TYPES } from '@/util/constants'

export default {
  name: 'SurveyWrap',
  components: {
    ScrollableWrap,
    FormSelect,
    FormMultiSelect,
    FormInput,
    BaseButton,
    TextButton,
    FocusHeading,
  },
  props: {
    surveyId: String,
  },
  data() {
    return {
      survey: {},
      surveyResponses: {},
      otherValueInput: undefined,
      selectedAnswers: [],
    }
  },
  emits: ['close'],
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    answeredAllQuestions() {
      return (
        this.survey.questions?.length ===
          Object.entries(this.surveyResponses).length &&
        Object.entries(this.surveyResponses).length > 0
      )
    },
  },
  methods: {
    randomizedSurveyQuestionAnswers(question) {
      let otherInputAnswer = question.answers.filter(
        (answer) => answer.otherInput !== undefined,
      )
      let allOtherAnswers = question.answers.filter(
        (answer) => answer.otherInput === undefined,
      )

      allOtherAnswers.sort(() => Math.random() - 0.5)

      return [...allOtherAnswers, ...otherInputAnswer]
    },
    handleInput({ question, response, index }) {
      if (question.type === USER_INPUT_ELEMENT_TYPES.MULTISELECT) {
        this.addOrRemoveAnswersToSelectedAnswersList({ response, index })
      } else {
        this.addResponseToSurveyResponses({ response, index })
      }
      this.otherValueInput = this.handleOtherValueInput({ question, response })

      if (this.otherValueInput) {
        prepareKeyboardFocus()
      }
    },
    addOrRemoveAnswersToSelectedAnswersList({ response, index }) {
      if (this.currentAnswersContainsResponse({ response })) {
        this.removeAnswerFromSelectedAnswersList({ response })
      } else {
        this.selectedAnswers.push(response)
      }
      this.surveyResponses[this.survey.questions[index].title] =
        this.selectedAnswers
    },
    currentAnswersContainsResponse({ response }) {
      return this.selectedAnswers.some((answer) => answer.id === response.id)
    },
    removeAnswerFromSelectedAnswersList({ response }) {
      this.selectedAnswers = this.selectedAnswers.filter(
        (answer) => answer.id !== response.id,
      )
    },
    addResponseToSurveyResponses({ response, index }) {
      this.surveyResponses[this.survey.questions[index].title] = response
    },
    handleOtherValueInput({ response }) {
      if (response.otherInput && response.selected) {
        return undefined
      } else if (
        this.selectedAnswers.length &&
        this.selectedAnswers.some((answer) => answer.otherInput)
      ) {
        const answer = this.selectedAnswers.find((answer) => answer.otherInput)
        return { ...answer.otherInput }
      } else if (response.otherInput) {
        return { ...response.otherInput }
      } else {
        return undefined
      }
    },
    handleOtherInput({ response }) {
      this.otherValueInput.value = response.value
    },
    async triggerRecordSurvey() {
      Object.entries(this.surveyResponses).forEach(([question, response]) => {
        if (Array.isArray(response)) {
          response.forEach((answer) => {
            this.sendAnswerToAnalytics({ question, response: answer })
          })
        } else {
          this.sendAnswerToAnalytics({ question, response })
        }
      })

      this.close()
    },
    sendAnswerToAnalytics({ question, response }) {
      this.$analytics.track({
        event: `${this.survey.title}: ${question}`,
        payload: {
          answer: response.value,
          otherInput: this.otherValueInput?.value,
        },
      })
    },
    triggerSkipSurvey() {
      this.$analytics.track({
        event: `Clicked Skip From ${this.survey.title}`,
      })
      this.close()
    },
    close() {
      this.$emit('close')
      closeSurvey({ store: this.$store, surveyId: this.surveyId })
    },
  },
  async created() {
    this.survey = await getSurvey({ surveyId: this.surveyId })
    this.$analytics.track({
      event: `Viewed ${this.survey.title}`,
    })
  },
}
</script>

<style lang="scss" scoped>
.survey-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.survey-icon {
  width: 56px;
  height: 56px;
  display: flex;
  background: var(--gray-200);
  color: var(--gray-700);
  align-items: center;
  justify-content: center;
  border-radius: var(--defaultBorderRadius);

  .dark & {
    background: var(--gray-900);
    color: var(--gray-500);
  }
}
.survey-content {
  flex: 1 0 auto;
}

.survey-block {
  margin-top: calc(var(--marginBase) * 3);
  &:first-child {
    margin-top: 0;
  }
  .survey-question {
    margin-top: 0;
    color: var(--gray-900);
    position: sticky;
    top: -8px;
    z-index: 10;
    background: white;
    padding: 10px 0;
    &.dark {
      color: var(--darkModeTextHigh);
      background: var(--darkModeBg);
    }
  }
  .other-input {
    margin-top: calc(var(--marginBase) * 1);
  }
}
</style>
