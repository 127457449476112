import mitt from 'mitt'
import {
  HANDOFF_EVENTS,
  TASK_WORKFLOW_VIEW,
  TASK_WORKFLOW_RESULT,
  MODAL_VIEW,
  EXIT_REASONS,
  ROUTES,
  SDK_EVENT_TYPES,
  DEEPLINK_STEPS,
  AUTHENTICATION_INTERACTION_EVENTS,
  DISTRIBUTION_TYPES,
} from '@/util/constants'
import { emitSdkEvent } from '@/util/sdk'
import { cleanupApplication } from '@/util/native/cleanup'
import { triggerSurvey, getAvailableExitSurvey } from '@/util/surveys'
import { isEmpty } from 'lodash-es'
import { maskNumber } from '@/util/general'
import { isADPConnector } from '@/util/connector'
import { featureUniqueForUSBank } from '@/util/customization'
import { taskService } from '@/services'

const emitter = mitt()

export function appEmit(event, payload) {
  emitter.emit(event, payload)
}

export function appSubscribe(event, callback) {
  emitter.on(event, callback)
}

export function appUnsubscribe(event, callback) {
  emitter.off(event, callback)
}

export function initiateExit({
  store,
  route,
  eventType,
  exitReason,
  handoffEvent,
} = {}) {
  if (_shouldTriggerSdkExitEvent({ store })) {
    _triggerSdkEvent({ store, route, eventType, exitReason, handoffEvent })
  } else {
    _triggerInterstitialExit({ store })
  }
}

export function appClose({
  store,
  eventType,
  exitReason,
  handoffEvent,
  route,
} = {}) {
  if (getAvailableExitSurvey({ store, route })) {
    store.dispatch('exit/updateExitData', {
      eventType,
      exitReason,
      handoffEvent,
    })
    triggerSurvey({ store, route })
  } else {
    if (
      eventType === SDK_EVENT_TYPES.CLOSE &&
      store.getters['taskWorkflow/taskCouldFailIfUserLeaves']
    ) {
      taskService.postUserEvent({ type: SDK_EVENT_TYPES.CLOSE })
    }

    _triggerSdkEvent({ store, route, eventType, exitReason, handoffEvent })
  }
}

function _triggerInterstitialExit({ store }) {
  store.dispatch('modal/openModal', {
    view: MODAL_VIEW.EXIT,
    overlay: true,
  })
}

export function shouldUseHandoff({ store, handoffEvent }) {
  const handoff = store.state.main.handoff || []

  return handoff.includes(handoffEvent)
}

function _shouldTriggerSdkExitEvent({ store }) {
  return (
    shouldUseHandoff({
      store,
      handoffEvent: HANDOFF_EVENTS.EXIT_PROMPT,
    }) ||
    store.state.taskWorkflow.taskWorkflowState.result ===
      TASK_WORKFLOW_RESULT.COMPLETED
  )
}

function _determineExitReason({ store, route }) {
  if (
    _hasSearched({ store }) &&
    _isSearchRoute({ route }) &&
    _zeroSearchResults({ store })
  ) {
    return EXIT_REASONS.ZERO_SEARCH_RESULTS
  } else if (_deeplinkToADPWrongConnector({ store })) {
    return EXIT_REASONS.WRONG_PAYROLL_PROVIDER
  } else if (store.state.taskWorkflow.taskWorkflowState.result) {
    switch (store.state.taskWorkflow.taskWorkflowState.result) {
      case TASK_WORKFLOW_RESULT.FULFILLMENT_AVAILABLE:
      case TASK_WORKFLOW_RESULT.ONLY_AUTH_COMPLETED:
        return EXIT_REASONS.TASK_PENDING
      default:
        return EXIT_REASONS.UNKNOWN
    }
  } else if (store.state.taskWorkflow.taskWorkflowState.view) {
    switch (store.state.taskWorkflow.taskWorkflowState.view) {
      case TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED_SUCCESS:
      default:
        return EXIT_REASONS.UNKNOWN
    }
  } else {
    return EXIT_REASONS.UNKNOWN
  }
}

function _triggerSdkEvent({
  store,
  route,
  eventType,
  exitReason,
  handoffEvent,
}) {
  if (!isEmpty(store.state.exit.data)) {
    eventType = store.state.exit.data.eventType
    exitReason = store.state.exit.data.exitReason
    handoffEvent = store.state.exit.data.handoffEvent
  }

  emitSdkEvent(eventType, {
    ...(eventType === SDK_EVENT_TYPES.CLOSE && {
      reason: exitReason || _determineExitReason({ store, route }),
    }),
    ...(_isFailReasonAvailableOnClose({ store, eventType }) && {
      failReason: store.state.task.failReason,
    }),
    ...(store.state.taskWorkflow.taskWorkflowId && {
      taskWorkflowId: store.state.taskWorkflow.taskWorkflowId,
    }),
    ...(store.state.task.taskId && { taskId: store.state.task.taskId }),
    ...(handoffEvent && { handoff: handoffEvent }),
    ...(_showCompanyData({ handoffEvent }) && {
      company: _getCompanyData({ store }),
    }),
    ...(_showAccountData({ store }) && {
      account: _getSelectedAccountData({ account: store.state.user.account }),
    }),
    ...(eventType === SDK_EVENT_TYPES.FINISH && {
      company: _getCompanyData({ store }),
    }),
    ...(eventType === SDK_EVENT_TYPES.FINISH && {
      distributionType: store.state.distribution.distributionType,
    }),
    ...(eventType === SDK_EVENT_TYPES.FINISH &&
      store.state.distribution.distributionType !==
        DISTRIBUTION_TYPES.TOTAL && {
        distributionAmount: store.state.distribution.distributionAmount,
      }),
    ...(_showUserActionData({ eventType, store }) && {
      action: _getUserActionData({ store }),
    }),
  })

  if (
    featureUniqueForUSBank({ customer: store.state.user.userData.customer })
  ) {
    emitSdkEvent(SDK_EVENT_TYPES.INTERACTION, {
      name: AUTHENTICATION_INTERACTION_EVENTS.CLOSED,
      value: {},
    })
  }

  cleanupApplication({ store })
}

function _showAccountData({ store }) {
  return (
    store.state.user.account &&
    store.getters['main/usesManualFallbackFeature'] &&
    store.getters['taskWorkflow/productsIncludeDeposit']
  )
}

function _showCompanyData({ handoffEvent }) {
  return handoffEvent === HANDOFF_EVENTS.AUTHENTICATION_SUCCESS
}

function _getCompanyData({ store }) {
  return {
    name: store.getters['company/companyName'],
    _id: store.getters['company/companyId'],
    branding: {
      logo: {
        url: store.state.company.branding?.logo?.url,
        backgroundColor: store.state.company.branding.logo?.backgroundColor,
      },
      color: store.state.company.branding?.color,
    },
  }
}

function _showUserActionData({ eventType, store }) {
  return (
    store.getters['taskWorkflow/userAction'] &&
    [SDK_EVENT_TYPES.CLOSE, SDK_EVENT_TYPES.FINISH].includes(eventType)
  )
}

function _getUserActionData({ store }) {
  const userAction = store.getters['taskWorkflow/userAction']
  const account = store.state.payLink.selections.find(
    (selection) => selection._id === userAction.accountId,
  )
  const taskStatus = account?.tasks?.[0]?.status

  const userActionData = {
    sessionId: userAction.sessionId,
    type: userAction.type,
    // NOTE: some actions are done by the user via webview, so no task is ran and we just mark it completed once they're done
    status: taskStatus ?? 'completed',
    accountId: userAction.account?.userCompanySelectionId,
  }

  return userActionData
}

function _getSelectedAccountData({ account }) {
  return {
    number: maskNumber(account.accountNumber),
    type: account.type,
    name: account.title,
  }
}

function _hasSearched({ store }) {
  return (
    store.state.search.companySearchQuery ||
    store.state.search.payrollSearchQuery
  )
}

function _isSearchRoute({ route }) {
  return route === ROUTES.SEARCH_COMPANY || route === ROUTES.SEARCH_PAYROLL
}

function _zeroSearchResults({ store }) {
  return (
    !store.state.search.employerCompanies.length &&
    !store.state.search.connectorCompanies.length
  )
}

function _deeplinkToADPWrongConnector({ store }) {
  return (
    store.state.main.deeplink?.step === DEEPLINK_STEPS.LOGIN_COMPANY &&
    isADPConnector(store.state.company.activeConnector)
  )
}

export function determineSdkEventTypeOnClose(taskWorkflowState) {
  return (
    taskWorkflowState?.result === TASK_WORKFLOW_RESULT.COMPLETED ||
    taskWorkflowState?.view ===
      TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED_SUCCESS
  )
}

function _isFailReasonAvailableOnClose({ store, eventType }) {
  return eventType === SDK_EVENT_TYPES.CLOSE && store.state.task.failReason
}
