<template>
  <div
    class="input-wrap"
    :class="{ dark: useDarkMode, 'has-error': data.hasError }"
    :id="`wrap-${data.inputId}`"
  >
    <div class="input-box" :class="{ dark: useDarkMode }">
      <div
        ref="label"
        class="label-container"
        :class="{
          'float-label': !data.label || data.placeholder,
          'activate-label': activateInputLabel,
          dark: useDarkMode,
        }"
      >
        <label
          :id="`inputLabel-${data.id}`"
          :for="`formInput-${data.id}`"
          aria-hidden="true"
          class="form-input-label"
        >
          {{ data.label || data.placeholder }}
        </label>
      </div>
      <div class="input-container">
        <span class="focus-bg"></span>
        <input
          ref="input"
          :id="`formInput-${data.id}`"
          class="input"
          :aria-describedby="`input-description-${data.id}`"
          :aria-labelledby="`inputLabel-${data.id}`"
          :class="{ dark: useDarkMode, glance_masked: usBankSpecificClass }"
          :value="inputValue"
          :name="`${data.name}`"
          :type="inputType"
          :data-test-id="`input-${data.name}`"
          :autocomplete="inputAutocompleteType"
          :pattern="data.pattern"
          autocapitalize="none"
          :spellcheck="false"
          :inputmode="inputMode"
          :required="!data.isOptional"
          v-mask:[data.maskType]="inputMask.mask"
          @input="handleInput($event)"
          @focus="handleFocus"
          @blur="handleBlur"
          @keyup.enter="handleEnter"
        />
        <!-- Used to capture password data from password managers and pass to our object -->
        <div
          class="password-manager-helper"
          v-if="
            inputType !== 'password' &&
            inputAutocompleteType !== 'one-time-code'
          "
        >
          <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
          <input
            :id="`password-text-field-${data.id}`"
            type="password"
            name="password"
            autocomplete="current-password"
            :value="passwordValue"
            @change="handlePasswordAutofill($event)"
            tabindex="-1"
            aria-hidden="true"
          />
        </div>
        <button
          class="show-value content-only"
          :class="{ dark: useDarkMode }"
          v-if="data.type === 'password'"
          @click="toggleShowValue"
        >
          <IconEyeOpen v-if="!showValue" alt="show" />
          <IconEyeClosed v-else alt="hide" />
          <span class="sr-only">{{
            showValue ? 'Hide Password' : 'Show Password'
          }}</span>
        </button>
        <transition name="fade">
          <span v-if="loading" class="loader">
            <Loading :size="{ width: '24px', height: '24px' }"></Loading>
          </span>
        </transition>
      </div>
    </div>
    <transition name="fade">
      <div class="subinput-text" v-if="inputDescription">
        <p
          v-if="
            inputDescription &&
            !(
              inputIsValid &&
              customerRequiresInputDescriptionToHideWhenInputIsValid
            )
          "
          class="paragraph"
          :id="`input-description-${data.id}`"
        >
          <span
            v-html="
              truncate(inputDescription, truncatedInputDescriptionLength, '...')
            "
          ></span>
        </p>
        <DecorativeImage
          class="icon icon-caret"
          src="/img/icon-chevron-right.svg"
          v-if="isInputDescriptionTruncated && !maximumDescriptionTextLength"
          :style="{
            opacity: !maximumDescriptionTextLength ? '0.7' : '0',
          }"
        />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="inputError"
        class="paragraph input-error"
        :class="{ dark: useDarkMode }"
        aria-live="assertive"
        :tab-index="data.hasError ? 1 : -1"
      >
        {{ inputError }}
      </div>
    </transition>
  </div>
</template>

<script>
import Loading from '@/components/Shared/Loading.vue'
import { mask } from 'vue-the-mask'
import { mapGetters, mapState } from 'vuex'
import {
  featureUniqueForPaypal,
  featureUniqueForUSBank,
} from '@/util/customization'
import isDate from 'validator/es/lib/isDate'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import isEmail from 'validator/es/lib/isEmail'
import isCreditCard from 'validator/es/lib/isCreditCard'
import { truncate } from '@/util/filters'
import DecorativeImage from '@/components/Shared/DecorativeImage.vue'
import IconEyeOpen from '@/components/Icons/Atomic/IconEyeOpen.vue'
import IconEyeClosed from '@/components/Icons/Atomic/IconEyeClosed.vue'
import { isExpiry, isState } from '@/util/validation'

export default {
  name: 'FormInput',
  props: {
    data: Object,
    branded: Boolean,
    dataTestId: String,
    autocomplete: String,
    loading: Boolean,
    initFocus: Boolean,
    initValue: String,
    focusDelay: {
      type: Number,
      default: 100,
    },
    showMaskDescription: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'inputUpdate',
    'inputFocus',
    'inputBlur',
    'inputEnter',
    'autofillPassword',
  ],
  components: { DecorativeImage, Loading, IconEyeOpen, IconEyeClosed },
  directives: {
    mask: {
      // we only want to bind the mask directive if the input has a defined maskType
      beforeMount(el, binding, vnode) {
        if (binding.arg) {
          mask(el, binding, vnode)
        }
      },
    },
  },
  data() {
    return {
      showValue: false,
      inputValue: '',
      passwordValue: '',
      focusTimeout: undefined,
      hasFocus: false,
      inputIsValid: false,
      defaultMaximumDescriptionTextLength: 44,
      maximumDescriptionTextLength: undefined,
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('company', ['brandColor']),
    ...mapGetters('theme', ['useDarkMode']),
    isMobile() {
      return IS_MOBILE
    },
    activateInputLabel() {
      return this.hasFocus || this.inputValue
    },
    inputDescription() {
      const maskTypeDescription =
        this.data.maskType && this.inputMask.example && this.showMaskDescription
          ? `${this.phrases.shared.formattedAs} ${this.inputMask.example}`
          : undefined

      const specificDescription = this.data.description

      return maskTypeDescription && specificDescription
        ? `${maskTypeDescription}. ${specificDescription}.`
        : maskTypeDescription || specificDescription || ''
    },
    isInputDescriptionTruncated() {
      return !!(
        this.inputDescription.length >
          this.defaultMaximumDescriptionTextLength && this.isMobile
      )
    },
    truncatedInputDescriptionLength() {
      const lengthToUse =
        this.maximumDescriptionTextLength ||
        this.defaultMaximumDescriptionTextLength
      return this.isMobile ? lengthToUse : this.inputDescription.length
    },
    inputError() {
      if (!this.data.hasError) return undefined

      return (
        this.data.errorMessage ??
        (this.data.type === 'email' && this.phrases.shared.enterValidEmail)
      )
    },
    inputMask() {
      const maskMap = {
        creditCard: {
          mask: '#### #### #### ####',
          validate: (value) => isCreditCard(value),
        },
        expDate: {
          mask: '##/##',
          validate: (value) => isExpiry(value),
        },
        cvv: {
          mask: '####',
          validate: (value) => value.match(/^\d\d\d$|^\d\d\d\d$/),
        },
        date: {
          mask: '##/##/####',
          example: 'MM/DD/YYYY',
          validate: (value) => {
            // The validator library throws an error when the date is formatted like 01/05
            // so just catch and return false
            try {
              return isDate(value, { format: 'MM/DD/YYYY' })
            } catch {
              return false
            }
          },
        },
        phone: {
          mask: '(###) ###-####',
          example: '(555) 555-1234',
          validate: (value) => isMobilePhone(value),
        },
        ssn: {
          mask: '###-##-####',
          example: '000-00-0000',
          validate: (value) => value.match(/^\d{3}-\d{2}-\d{4}$/),
        },
        ssnLast4: {
          mask: '####',
          validate: (value) => value.match(/^\d{4}$/),
        },
        state: {
          mask: 'AA',
          example: 'TX',
          validate: (value) => isState(value),
        },
        postalCode: {
          mask: '#####',
          example: '54321',
          validate: (value) => value.match(/^\d{5}(-\d{4})?$/),
        },
      }

      return maskMap[this.data.maskType] || {}
    },
    inputType() {
      return this.showValue && this.data.type === 'password'
        ? 'text'
        : this.data.type
    },
    inputMode() {
      const inputModeMap = {
        '10key': 'numeric',
      }
      return inputModeMap[this.data.keyboardType]
    },
    inputAutocompleteType() {
      return (
        this.data.autocompleteType ||
        (this.data.type === 'password' ? 'current-password' : 'username')
      )
    },
    customerRequiresInputDescriptionToHideWhenInputIsValid() {
      return featureUniqueForPaypal({ customer: this.userData.customer })
    },
    usBankSpecificClass() {
      return featureUniqueForUSBank({ customer: this.userData.customer })
    },
  },
  methods: {
    truncate,
    // We have to use :value instead of v-model to allow for Android keyboards
    // to respond to every keystroke
    handleInput($event) {
      this.inputValue = $event.target.value.trim()
      this.inputIsValid = this.checkIfValidationPassed(this.inputValue)

      this.emitUpdate(this.inputIsValid ? this.inputValue : '')
    },
    handlePasswordAutofill($event) {
      // If we are on a password field, then just set the password
      if (this.data.type === 'password') {
        // Hide password if we are using autofill
        this.showValue = false
        this.handleInput($event)
      } else {
        // Otherwise, find a password field to autofill
        this.$emit('autofillPassword', $event.target.value)
      }
    },
    checkIfValidationPassed(value) {
      if (!value) return false

      return !(
        (this.data.maskType && !this.passesMaskTypeCheck(value)) ||
        (this.data.type === 'email' && !this.passesEmailCheck(value))
      )
    },
    emitUpdate(value) {
      // eslint-disable-next-line vue/no-mutating-props
      this.data.value = value
      this.$emit('inputUpdate', this.data)
    },
    handleFocus() {
      this.hasFocus = true
      this.$emit('inputFocus')
      this.maximumDescriptionTextLength = undefined
    },
    handleBlur() {
      this.hasFocus = false
      this.$emit('inputBlur')
      this.maximumDescriptionTextLength = 300
    },
    handleEnter() {
      this.$emit('inputEnter')
    },
    toggleShowValue() {
      this.showValue = !this.showValue
    },
    passesMaskTypeCheck(value) {
      return !!this.inputMask.validate(value)
    },
    passesEmailCheck(value) {
      return isEmail(value)
    },
  },
  async mounted() {
    this.inputValue = this.initValue
    // If we are initializing it with a password autofilled, then hide the value
    if (this.initValue && this.data.type === 'password') this.showValue = false
    if (this.initFocus) {
      this.focusTimeout = setTimeout(
        () => this.$refs.input.focus(),
        this.focusDelay,
      )
    }
  },
  unmounted() {
    clearTimeout(this.focusTimeout)
  },
}
</script>

<style lang="scss" scoped>
$input-height: 56px;

@mixin dark-mode($property, $value) {
  &.dark {
    #{$property}: $value;
  }
}

@mixin has-error($property, $value, $darkModeValue) {
  .input-wrap.has-error & {
    #{$property}: $value;

    .dark & {
      #{$property}: $darkModeValue;
    }
  }
}

.input-wrap {
  position: relative;
  margin-top: calc(var(--marginBase) * 3);
}

.input-box {
  position: relative;
  background: var(--gray-50);
  @include dark-mode(background, var(--gray-900));
  @include has-error(background, var(--error-50), var(--error-700));
  border-bottom: solid 2px var(--gray-200);
  @include dark-mode(border-bottom, solid 2px var(--gray-800));
  @include has-error(border-bottom-color, var(--error), var(--error-500));
  border-radius: var(--smallBorderRadius) var(--smallBorderRadius) 0 0;
  display: flex;
  align-items: flex-end;
  height: $input-height;
  overflow: hidden;
  transition: border 200ms ease;

  &:focus-within,
  &:active {
    border-color: var(--gray-300);
    @include dark-mode(border-color, var(--gray-700));

    .focus-bg {
      transform: scaleX(1);
    }
  }

  .input-container {
    position: relative;
    width: 100%;
  }

  .focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gray-100);
    @include has-error(background, var(--error-50), var(--error-600));
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 150ms ease;

    .dark & {
      background: var(--gray-800);
    }
  }

  .label-container {
    &.float-label {
      position: absolute;
      top: 50%;
      left: calc(var(--paddingBase) * 2);
      transition: transform 250ms cubic-bezier(0.55, 0, 0.1, 1);
      transform: translateY(-50%);
      transform-origin: left center;
      will-change: transform, color;
      color: var(--gray-600);
      @include dark-mode(color, var(--gray-500));
      @include has-error(color, var(--error-500), var(--error-300));
      font-size: 1.8rem;
      overflow: hidden;
      z-index: 2;

      &.activate-label {
        transform: translateY(-90%) scale(0.7);
        color: var(--gray-700);
        @include dark-mode(color, var(--gray-400));
        @include has-error(color, var(--error-600), var(--error-200));

        .form-input-label {
          opacity: 1;
        }
      }
    }

    .form-input-label {
      white-space: nowrap;
      overflow: hidden;
      opacity: 0.8;
      transition: opacity, color 250ms linear;
    }
  }

  .input {
    -webkit-appearance: none;
    // prevents browser auto-filled background color
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--gray-900);
    @include dark-mode(-webkit-text-fill-color, var(--darkModeTextHigh));
    background-color: transparent !important;
    border: none;
    border-radius: 0;
    caret-color: var(--gray-900);
    @include dark-mode(caret-color, var(--darkModeTextHigh));
    color: var(--gray-900);
    @include dark-mode(color, var(--darkModeTextHigh));
    display: block;
    font-size: 1.8rem;
    height: $input-height;
    margin: 0 auto;
    outline: none;
    padding: calc(var(--paddingBase) * 2.5) calc(var(--paddingBase) * 2) 0;
    transition: border 250ms cubic-bezier(0.55, 0, 0.1, 1);
    width: 100%;
    position: relative;
    z-index: 2;

    &::placeholder {
      color: var(--lightModePlaceholderColor);
      opacity: 1;

      @include dark-mode(color, var(--darkModePlaceholderColor));
      @include has-error(color, var(--error-500), var(--error-300));
    }
  }

  .show-value {
    color: var(--gray-600);
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: $input-height;
    display: flex;
    right: calc(var(--paddingBase) * 2);
    justify-content: end;
    align-items: center;
    z-index: 2;
    background: transparent;
    top: 16px;
    transform: translateY(-16px);
    @include dark-mode(color, var(--gray-500));
  }
}

.subinput-text {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 1.2rem;
  display: flex;
  margin-top: calc(var(--marginBase) * 0.5);

  .paragraph {
    font-size: 1.2rem;
    line-height: 2rem;
    margin-top: calc(var(--marginBase) * 0.5);
    @include dark-mode(color, var(--gray-500));
    @include has-error(color, var(--error-500), var(--error-300));
  }
}

.input-error {
  font-size: 1.2rem;
  line-height: 1.5;
  color: var(--error);
  @include dark-mode(color, var(--error-300));
}

.password-manager-helper {
  width: 0px;
  height: 0px;
  overflow: hidden;
}
</style>
