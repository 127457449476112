import {
  getRequiredElementData,
  parseAuthenticationSteps,
} from '@/util/form-flow'
import { PROGRESS_STEP_TYPES, TASK_WORKFLOW_VIEW } from '@/util/constants'
import {
  handleTaskCredentialsBypass,
  startTaskFormBypass,
  initTaskWorkflow,
} from '@/util/task-workflow'

/**
 * If we are running the auth in the background (aka the user fills out the inputs using our form instead of a native webview)
 * then we will set the form values as a templated patterned like `{atomic}username{/atomic}`. This way when
 * we receive evaluate and network requests we will pull the real values that the user typed in and replace
 * the template value with the real value
 */
export function initTemplatedFormData({ store }) {
  if (
    !store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']
      .runAuthInBackground
  ) {
    return
  }
  const { steps } = parseAuthenticationSteps({
    store,
    connector: store.state.company.activeConnector,
    elements:
      store.state.company.activeConnector?.options?.sections[0]?.elements,
  })
  steps.forEach((element) =>
    store.dispatch('formFlow/addTemplatedFormData', {
      key: `auth.${element.name}`,
      data: getRequiredElementData({
        ...element,
        value: `{atomic}${element.name}{/atomic}`,
      }),
    }),
  )
}

export function initTaskWorkflowState({ store }) {
  store.dispatch('taskWorkflow/resetTaskWorkflowState', {
    initialView: store.getters[
      'userDeviceAutomation/parsedUserAutomationDeviceConfig'
    ].runAuthInBackground
      ? TASK_WORKFLOW_VIEW.LOGIN
      : TASK_WORKFLOW_VIEW.PLACEHOLDER,
  })
}

export async function startTask({ store, demo }) {
  await store.dispatch('progress/updateInitialCompletedProgressSteps', [
    PROGRESS_STEP_TYPES.CONNECTION,
    PROGRESS_STEP_TYPES.AUTHENTICATION,
  ])

  if (store.getters['main/isScopePayLink']) {
    await handleTaskCredentialsBypass({ store, demo })
  } else if (store.state.main.linkedAccountId) {
    await handleTaskCredentialsBypass({ store })
  } else if (store.getters['taskWorkflow/productsIncludeDeposit']) {
    await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION,
    })
  } else {
    await startTaskFormBypass({ store })
    await initTaskWorkflow({ store })
  }
}
