<template>
  <ModalContent>
    <ModalBody>
      <ModalContentWrap>
        <DynamicHeader
          :title="exitContent.title"
          :description="exitContent.description"
          textAlignment="text-center"
        />
        <HelpAction
          v-if="exitContent.helperAction?.text"
          :text="exitContent.helperAction?.text"
          :method="exitContent.helperAction?.method"
        />
        <div class="action-list">
          <BaseButton
            v-for="(item, index) in exitContent.actionItems"
            :key="index"
            :text="item.text"
            :customer-branded="item.customerBranded"
            :task-branded="item.taskBranded"
            :btn-style="index > 0 ? 'hollow' : 'solid'"
            :data-test-id="item.dataTestId"
            @handleClick="item.method"
          />
          <TextButton text="Close" @handleClick="triggerCloseModal" />
        </div>
        <teleport to="#popover-content">
          <SurveyWrap v-if="activeSurvey" :surveyId="activeSurvey" />
        </teleport>
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import { PAGES } from '@/util/constants'
import BaseButton from '@/components/Form/BaseButton.vue'
import TextButton from '@/components/Form/TextButton.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import DynamicHeader from '@/components/Header/DynamicHeader.vue'
import HelpAction from '@/components/Shared/HelpAction.vue'
import SurveyWrap from '@/components/Survey/SurveyWrap.vue'
import { buildExitContent } from '@/util/exit'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ExitModal',
  components: {
    ModalContentWrap,
    ModalContent,
    ModalBody,
    BaseButton,
    TextButton,
    DynamicHeader,
    HelpAction,
    SurveyWrap,
  },
  computed: {
    ...mapGetters('survey', ['activeSurvey']),
    exitContent() {
      const exitContent = buildExitContent({
        store: this.$store,
        router: this.$router,
      })
      return exitContent
    },
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    async triggerCloseModal() {
      this.$analytics.track({
        event: `Clicked No, Continue From ${PAGES.EXIT_CONFIRMATION}`,
      })
      await this.closeModal()
    },
  },
  async created() {
    this.$analytics.track({
      event: `Viewed ${PAGES.EXIT_CONFIRMATION}`,
    })
  },
}
</script>
