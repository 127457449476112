const stateAbbreviations = {
  AL: 'ALABAMA',
  AK: 'ALASKA',
  AZ: 'ARIZONA',
  AR: 'ARKANSAS',
  CA: 'CALIFORNIA',
  CO: 'COLORADO',
  CT: 'CONNECTICUT',
  DC: 'DISTRICT OF COLUMBIA',
  DE: 'DELAWARE',
  FL: 'FLORIDA',
  GA: 'GEORGIA',
  HI: 'HAWAII',
  ID: 'IDAHO',
  IL: 'ILLINOIS',
  IN: 'INDIANA',
  IA: 'IOWA',
  KS: 'KANSAS',
  KY: 'KENTUCKY',
  LA: 'LOUISIANA',
  ME: 'MAINE',
  MD: 'MARYLAND',
  MA: 'MASSACHUSETTS',
  MI: 'MICHIGAN',
  MN: 'MINNESOTA',
  MS: 'MISSISSIPPI',
  MO: 'MISSOURI',
  MT: 'MONTANA',
  NE: 'NEBRASKA',
  NV: 'NEVADA',
  NH: 'NEW HAMPSHIRE',
  NJ: 'NEW JERSEY',
  NM: 'NEW MEXICO',
  NY: 'NEW YORK',
  NC: 'NORTH CAROLINA',
  ND: 'NORTH DAKOTA',
  OH: 'OHIO',
  OK: 'OKLAHOMA',
  OR: 'OREGON',
  PA: 'PENNSYLVANIA',
  RI: 'RHODE ISLAND',
  SC: 'SOUTH CAROLINA',
  SD: 'SOUTH DAKOTA',
  TN: 'TENNESSEE',
  TX: 'TEXAS',
  UT: 'UTAH',
  VT: 'VERMONT',
  VA: 'VIRGINIA',
  WA: 'WASHINGTON',
  WV: 'WEST VIRGINIA',
  WI: 'WISCONSIN',
  WY: 'WYOMING',
}

/**
 * Coerces value to a valid state abbreviation if possible.
 * @param {string} value
 * @returns {string | undefined}
 *
 * @example asStateAbbreviation('California') // 'CA'
 * @example asStateAbbreviation('ca') // 'CA'
 */
export function asStateAbbreviation(value) {
  if (typeof value !== 'string') return undefined

  const normalizedValue = value.trim().toUpperCase()

  return normalizedValue in stateAbbreviations
    ? normalizedValue
    : Object.keys(stateAbbreviations).find(
        (key) => stateAbbreviations[key] === normalizedValue,
      )
}

/**
 * Validates whether value is a valid state abbreviation or name.
 * @param {*} value
 */
export function isState(value) {
  return Boolean(asStateAbbreviation(value))
}
