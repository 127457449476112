/**
 * Determines whether value is a valid expiry date in MM/YY format
 * @param {*} value - Value to check
 * @param {*} today - Allows for testing by setting today's date
 * @returns
 */
export function isExpiry(value, today = new Date()) {
  const expiryPattern = /^\d\d\/\d\d$/

  if (!value.match(expiryPattern)) return false

  const [month, year] = value.split('/').map(Number)
  const currentMonth = today.getMonth() + 1
  const currentYear = today.getFullYear() % 100

  if (month < 1 || month > 12) return false
  if (year < currentYear || (year === currentYear && month < currentMonth))
    return false
  if (year > currentYear + 10) return false

  return true
}
