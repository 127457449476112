export const SDK_EVENT_TYPES = {
  AUTH_STATUS_UPDATED: 'atomic-transact-auth-status-updated',
  CLOSE: 'atomic-transact-close',
  FINISH: 'atomic-transact-finish',
  OPEN_URL: 'atomic-transact-open-url',
  INTERACTION: 'atomic-transact-interaction',
  DATA_REQUEST: 'atomic-transact-data-request',
  AUTOMATION_HANDOFF: 'atomic-transact-automation-handoff',
  LAUNCH: 'atomic-transact-launch',
  TASK_STATUS_UPDATED: 'atomic-transact-task-status-updated',
}
