import { Quantum } from '@atomicfi/quantum-js'
import { requestInterceptionScript } from '@/util/client-side-automation/page-scripts'
import { receivedInterceptedRequest } from '@/util/client-side-automation/received-intercepted-request'

export async function createUserActionTaskPage({ store }) {
  const { page } = await Quantum.launch({
    interceptRequests: false,
  })
  await store.dispatch('userDeviceAutomation/addPage', page)

  await _setupPageForUserActionFlow({ store, page })

  return page
}

async function _setupPageForUserActionFlow({ store, page }) {
  const config =
    store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']

  await page.on('finished', async () => {
    await page.evaluate(requestInterceptionScript({ config }), [
      config.requestHook,
      config.responseHook,
    ])
  })

  await page.on('dispatch', (event) => {
    switch (event.detail.data?.type) {
      case 'request':
        return receivedInterceptedRequest({
          store,
          request: event.detail.data.request,
        })
    }
  })
}
