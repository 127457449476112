<template>
  <div
    class="modal-content-wrap"
    :class="{
      'powered-by-atomic-enabled': poweredByAtomic,
      'overlay-padding': modalState.overlay,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ModalContentWrap',
  computed: {
    ...mapGetters('theme', ['poweredByAtomic']),
    ...mapState('modal', ['modalState']),
  },
}
</script>

<style lang="scss" scoped>
.modal-content-wrap {
  background: var(--white);
  border-radius: var(--defaultBorderRadius);
  padding: var(--screenPaddingValues);
  padding-top: #{max(24px, env(safe-area-inset-top))};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .dark & {
    background: var(--gray-950);
  }
  &.powered-by-atomic-enabled {
    padding-bottom: 10px;
  }
  &.overlay-padding {
    padding: var(--screenPaddingValues);
    padding-top: #{max(40px, env(safe-area-inset-top))};
  }

  @media (max-width: 876px) {
    border-radius: 12px 12px 0px 0px;
  }
}
</style>

<!-- eslint-disable-next-line -->
<style lang="scss">
.modal-content-wrap {
  .scrollable-wrap {
    .fade-bar {
      &.top {
        &.dark {
          background: linear-gradient(
            0deg,
            rgba(24, 28, 48, 0) 0%,
            rgba(24, 28, 48, 1) 100%
          );
        }
      }
      &.bottom {
        &.dark {
          background: linear-gradient(
            180deg,
            rgba(24, 28, 48, 0) 0%,
            rgba(24, 28, 48, 0.5) 40%,
            rgba(24, 28, 48, 1) 100%
          );
        }
      }
    }
  }
}
.fixed-modal-title {
  font-size: 2rem;
  padding: calc(24px + env(safe-area-inset-top)) 24px 16px;
  border-bottom: 1px solid var(--gray-200);
  width: 100%;
  background: var(--white);
  z-index: 1;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dark & {
    background: var(--gray-950);
    border-bottom: 1px solid var(--gray-900);
  }
}
</style>
