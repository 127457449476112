<template>
  <div
    class="action-bar-wrapper"
    :class="{
      hidden: coverActionBar,
      'inline-layout': useInlineLayout,
    }"
  >
    <div class="action-bar">
      <transition name="fade">
        <button
          v-if="showBackButton"
          class="icon-back content-only"
          @click="handleBack"
          data-test-id="back"
          id="backButton"
          aria-label="Back"
        >
          <IconBack aria-hidden="true" />
        </button>
      </transition>

      <FocusHeading
        data-test-id="page-title"
        :level="3"
        class="title has-back-button"
      >
        {{ phrases?.shared?.manageDirectDeposit }}
      </FocusHeading>

      <transition name="fade">
        <CloseButton
          v-if="showExitButton && inSdk"
          v-store-focused-element:@click="handleClose"
          data-test-id="close-x"
          id="closeButton"
          :disabled="state === 'loading'"
        />
      </transition>
    </div>
    <div v-if="shouldShowDisclaimer" class="disclaimer">
      {{ phrases.shared.disclaimer({ customerName }) }}
      <LegalDisclaimerActions :separated="true" />.
    </div>
  </div>
</template>

<script>
import {
  APP_EVENTS,
  ROUTES,
  TASK_WORKFLOW_VIEW,
  SDK_EVENT_TYPES,
  HANDOFF_EVENTS,
} from '@/util/constants'
import { mapState, mapGetters, mapActions } from 'vuex'
import {
  appSubscribe,
  appUnsubscribe,
  initiateExit,
  determineSdkEventTypeOnClose,
  shouldUseHandoff,
} from '@/util/app-event'
import { backButtonHandler } from '@/util/back-button'
import CloseButton from '@/components/Shared/CloseButton.vue'
import IconBack from '@/components/Icons/IconBack.vue'
import LegalDisclaimerActions from '@/components/Legal/LegalDisclaimerActions.vue'

export default {
  name: 'PNCActionBar',
  components: {
    CloseButton,
    IconBack,
    LegalDisclaimerActions,
  },
  data() {
    return {
      state: 'idle',
    }
  },
  computed: {
    ...mapGetters('theme', ['useInlineLayout']),
    ...mapState('company', ['_id']),
    ...mapState('main', ['coverActionBar', 'inSdk', 'deeplink']),
    ...mapState('taskWorkflow', ['taskWorkflowState']),
    ...mapState('experiment', ['ldFlags']),
    ...mapGetters('main', ['showBackButton']),
    ...mapGetters('theme', ['showCloseButton']),
    ...mapGetters('i18n', ['phrases']),
    ...mapGetters('user', ['customerName']),

    showExitButton() {
      const currentRoute = this.$route.name
      return (
        currentRoute &&
        currentRoute !== ROUTES.INITIALIZE &&
        this.taskWorkflowState?.view !== TASK_WORKFLOW_VIEW.IN_PROGRESS &&
        this.showCloseButton
      )
    },
    shouldShowDisclaimer() {
      return [
        ROUTES.SEARCH_PAYROLL,
        ROUTES.SEARCH_COMPANY,
        ROUTES.PAY_LINK_SEARCH,
      ].includes(this.currentRoute())
    },
  },
  methods: {
    ...mapActions('search', ['trackSearchPerformanceEvents']),
    currentRoute() {
      return this.$router?.currentRoute.value.name
    },
    handleBack() {
      backButtonHandler({ store: this.$store })
    },
    handleClose() {
      this.state = 'loading'
      this.trackSearchPerformanceEvents()

      this.$analytics.track({
        event: 'Clicked Close',
        payload: { exitScreen: this.$route.path },
      })

      const closeAppIfSearchTrackingNotComplete = () => {
        this.state = 'idle'
        initiateExit({
          store: this.$store,
          eventType: this.determineSdkEvent(),
          route: this.currentRoute(),
          ...(shouldUseHandoff({
            store: this.$store,
            handoffEvent: HANDOFF_EVENTS.EXIT_PROMPT,
          }) && { handoffEvent: HANDOFF_EVENTS.EXIT_PROMPT }),
        })
      }

      setTimeout(closeAppIfSearchTrackingNotComplete, 500)
    },
    determineSdkEvent() {
      return determineSdkEventTypeOnClose(this.taskWorkflowState)
        ? SDK_EVENT_TYPES.FINISH
        : SDK_EVENT_TYPES.CLOSE
    },
  },
  created() {
    appSubscribe(APP_EVENTS.HANDLE_CLOSE, this.handleClose)
  },
  unmounted() {
    appUnsubscribe(APP_EVENTS.HANDLE_CLOSE, this.handleClose)
  },
}
</script>

<style lang="scss" scoped>
.action-bar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  &.hidden {
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }
}

.action-bar {
  position: relative;
  left: 0;
  width: 100%;
  z-index: 1;
  min-height: calc(var(--headerHeight) + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) 24px 0;
  opacity: 1;
  background-color: #0069aa;

  .inline-layout & {
    background-color: transparent;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;

  transition: opacity 0.5s 0.3s;

  &.hidden {
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  .title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    animation: fadeIn 250ms ease-in 250ms forwards;
    opacity: 0;
    transition: padding-left 250ms cubic-bezier(0.39, 0.575, 0.565, 1);
    color: var(--white);
    font-size: 16px !important;
    text-transform: uppercase;

    .inline-layout & {
      display: none;
    }
  }

  %icon-base {
    cursor: pointer;
    display: flex;
    margin-left: auto;
    margin-right: -8px;
    padding: 8px;
    transition: color 200ms ease-in-out;

    .inline-layout & {
      color: var(--gray-700);
      stroke: var(--gray-700);
    }

    color: var(--white);
    stroke: var(--white);

    &.dark {
      color: var(--white);
      stroke: var(--white);
    }
  }

  .icon-close {
    @extend %icon-base;
    z-index: 1;
  }

  .icon-back {
    @extend %icon-base;
    left: 8px;
    position: absolute;
    z-index: 1;

    &.fade-leave-active {
      display: none;
    }
  }
}

.disclaimer {
  background-color: var(--white);
  padding: 8px 24px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;

  font-size: 12px;
  line-height: 100%;
  color: var(--gray-700);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
