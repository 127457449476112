<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    v-bind="iconSize"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0004 4.96875C9.13873 4.96875 6.80575 6.23347 5.07715 7.67618C3.3509 9.11693 2.16737 10.783 1.57462 11.7216L1.55054 11.7596C1.43292 11.9447 1.2802 12.1851 1.203 12.5219C1.14067 12.7939 1.14067 13.1436 1.203 13.4156C1.2802 13.7524 1.43291 13.9928 1.55053 14.1779L1.57462 14.2159C2.16737 15.1545 3.3509 16.8206 5.07715 18.2613C6.80575 19.704 9.13873 20.9688 12.0004 20.9688C14.8621 20.9688 17.1951 19.704 18.9237 18.2613C20.6499 16.8206 21.8334 15.1545 22.4262 14.2159L22.4503 14.1779C22.5679 13.9928 22.7206 13.7524 22.7978 13.4156C22.8601 13.1436 22.8601 12.7939 22.7978 12.5219C22.7206 12.1851 22.5679 11.9447 22.4503 11.7596L22.4262 11.7216C21.8334 10.783 20.6499 9.11693 18.9237 7.67618C17.1951 6.23347 14.8621 4.96875 12.0004 4.96875Z"
      v-bind="iconTwoToneFillOne"
    />
    <circle cx="12" cy="12.9688" r="3" v-bind="iconTwoToneFillTwo" />
    <rect
      x="1.03906"
      y="4.83594"
      width="2"
      height="27"
      rx="1"
      transform="rotate(-45 1.03906 4.83594)"
      v-bind="iconTwoToneFillOne"
    />
    <rect
      x="2.45312"
      y="3.42188"
      width="1.3856"
      height="27"
      rx="0.6928"
      transform="rotate(-45 2.45312 3.42188)"
      fill="white"
    />
  </svg>
</template>
<script>
import IconBase from '@/components/Icons/Atomic/IconBase.vue'

export default {
  name: 'IconEyeClosed',
  extends: IconBase,
}
</script>
